export const stateInitial = {
  loading: false,
  error: false,
  loaded: false,
  awaiting: false,
  errorMessage: "",
  loadedMessage: ""
};
export const stateLoading = {
  loading: true,
  error: false,
  loaded: false,
  awaiting: false,
  errorMessage: "",
  loadedMessage: ""
};
export const stateLoaded = {
  loading: false,
  error: false,
  loaded: true,
  awaiting: false,
  errorMessage: ""
};
export const stateError = {
  loading: false,
  error: true,
  loaded: false,
  awaiting: false,
  errorMessage: "",
  loadedMessage: ""
};

export const stateAwaitingUserInput = {
  loading: false,
  error: false,
  loaded: false,
  awaiting: true,
  errorMessage: "",
  loadedMessage: ""
};

export const ignoreSettingsSaveKey = "ignoreSettingsSave";
export const kpiAppSettingsKey = "kpiDashboardFilter";
export const daterangeAppSettingsKey = "daterangeFilter";

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

/**
 * Delay a function execution. Useful when the render/ unrender of a big number of components should happen simultaneously
 * @param {function} callback function to be executed sometime later
 * @param {number} delay number of miliseconds to delay the `callback` execution
 * @param {boolean} shouldSparse if set, will add noise to the delay, so that multiple callbacks will not be executed simultaneously
 */
export function executeAsync(callback, delay, shouldSparse) {
  if (!callback) return;

  if (!delay) delay = 0;

  if (shouldSparse) delay *= Math.random();

  setTimeout(() => {
    callback();
  }, delay);
}

/**
 * Helper function to try/ catch callback functions. Can be async
 * @param {function} tryCb Callback that we try to execute. Can be async
 * @param {function} catchCb Callback to handle the error
 */
export async function executeTryCatchCallbacks(tryCb, catchCb) {
  try {
    await tryCb();
  } catch (error) {
    catchCb(error);
  }
}

let appSettingsSaveTimer = null;
export async function execWithCooldown(callback, timeoutMs = 100) {
  clearTimeout(appSettingsSaveTimer);
  appSettingsSaveTimer = setTimeout(callback, timeoutMs);
}

export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((item, index) => item === arr2[index]);
}
