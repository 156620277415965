import axios from "axios";
import { apiRoot } from "@/preferences";

/**
 * Transforms an object to a query parameters string,
 * using only the keys where the value is not null
 * @param {Object} dct key, value pair representing query parameter, value pair
 * @returns string representation of the query parameters
 */
export function QS(dct) {
  return new URLSearchParams(
    Object.fromEntries(
      Object.entries(dct).filter(
        ([, v]) => v != null && (typeof v === "number" || v.length > 0 || typeof v === "boolean")
      )
    )
  ).toString();
}

export function QSList(query, key) {
  if (!Array.isArray(query)) {
    throw new Error("Query parameter must be an array");
  }
  return query.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join("&");
}

export function getMenu() {
  return axios.get(`${apiRoot}/menu/`);
}
