/**
 *
 * @param {String} created_date date of object creation
 * @param {String} modified_date date of modifying object
 * @returns modified date if it was modified after 100ms after creation or null otherwise
 */
export function checkDateUpdate(created_date, modified_date) {
  const modifiedDate = new Date(modified_date).getTime();
  const createdDate = new Date(created_date).getTime();
  const delta = modifiedDate - createdDate;
  return delta < 100 ? null : modified_date;
}

/**
 * Creates a deepcopy of the passed object that can be an array or object
 * @param {Array|Object} obj to be deep copied
 * @returns deepcopy of obj
 */
export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}
