import axios from "axios";
import { apiRoot, warehouseRoot } from "../preferences";
import { QS, QSList } from "@/services/util.js";

export function getIntegrations() {
  return axios.get(`${apiRoot}/integration/endpoints/`);
}

export function setIntegrationTokens(name, data) {
  return axios.put(`${apiRoot}/integration/tokens/${name}/`, data);
}

export function getWizardOptions(name, query) {
  return axios.get(`${apiRoot}/integration/wizard/${name}/options/?${QS(query)}`);
}

export function getWizardData(name, query) {
  return axios.get(`${apiRoot}/integration/wizard/${name}/?${QS(query)}`);
}

export function setWizardData(name, data) {
  return axios.put(`${apiRoot}/integration/wizard/${name}/`, data);
}

export function deleteWizardData(name) {
  return axios.delete(`${apiRoot}/integration/wizard/${name}/`);
}

export function uploadIntegrationExcel(options) {
  const {
    integration,
    file,
    snapshot_name,
    snapshot_suffix,
    header_row,
    exported_date = null,
    is_protected = false
  } = options;
  const formData = new FormData();
  formData.append("file", file);
  if (snapshot_name) {
    formData.append("snapshot_name", snapshot_name);
  } else {
    formData.append("snapshot_name", null);
  }
  if (snapshot_suffix) {
    formData.append("snapshot_suffix", snapshot_suffix);
  }
  if (header_row) {
    formData.append("header_row", header_row);
  }
  if (exported_date) {
    formData.append("exported_date", exported_date);
  }
  if (is_protected) {
    formData.append("is_protected", is_protected);
  }
  return axios.post(`${apiRoot}/integration/excel/${integration}/`, formData);
}

export function wizardExcelData(file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(`${apiRoot}/integration/wizard-data/excel/`, formData);
}

export function triggerKpiCalculator(name, rerun = false) {
  return axios.get(`${apiRoot}/integration/kpicalculator/${name}/?rerun=${rerun}`);
}

export function getLastRerunLog() {
  return axios.get(`${apiRoot}/rerunlog/last/`);
}

export function uploadZipFile(file, snapshot_name) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(`${warehouseRoot}/snapshot/zip/${snapshot_name}`, formData);
}

export function getArchivedFilesFromZip(snapshot_name, snapshot_date) {
  return axios.get(`${warehouseRoot}/snapshot/zip/files/${snapshot_name}?date=${snapshot_date}`);
}

export function deleteZipFile(snapshot_name, snapshot_date) {
  return axios.delete(`${warehouseRoot}/snapshot/zip/${snapshot_name}?date=${snapshot_date}`);
}

export function getDataIssuesSummary() {
  return axios.get(`${warehouseRoot}/dataissuesummary`);
}

export function getObjectDataIssues(query) {
  return axios.get(`${warehouseRoot}/dataissues?${QS(query)}`);
}

export function getDataIssuesDetails(query) {
  return axios.get(`${warehouseRoot}/dataissues/detailed?${QS(query)}`);
}

export function getIntegrationsSummary(query) {
  return axios.get(`${warehouseRoot}/integrations/summary?${QSList(query, "integrations")}`);
}

export function ignoreDataIssue(payload, query) {
  return axios.post(`${warehouseRoot}/dataissues/ignore?${QS(query)}`, payload);
}

export function getDataIssuesGrouped(query) {
  return axios.get(`${warehouseRoot}/dataissues/grouped?${QS(query)}`);
}

export function getChildrenWizards(name) {
  return axios.get(`${apiRoot}/integration/wizard/${name}/parent/`);
}

export function getDatevIntegrations() {
  return axios.get(`${apiRoot}/integration/datev/`);
}

export function createNewDatevIntegration() {
  return axios.post(`${apiRoot}/integration/datev/`);
}

export function updateDatevIntegration(id, payload) {
  return axios.patch(`${apiRoot}/integration/datev/${id}/`, payload);
}

export function deleteDatevIntegration(id) {
  return axios.delete(`${apiRoot}/integration/datev/${id}/`);
}
