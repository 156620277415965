const initialState = {
  exportedDates: {}
};

const mutations = {
  setExportedDate(state, { id, date }) {
    state.exportedDates[id] = date;
  },
  clearExportedDates(state) {
    state.exportedDates = {};
  }
};

const actions = {
  addExportedDate({ commit }, { id, date }) {
    commit("setExportedDate", { id, date });
  },
  clearAllExportedDates({ commit }) {
    commit("clearExportedDates");
  }
};

const getters = {
  exportedDates: state => state.exportedDates
};

export const integrationModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
