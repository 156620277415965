/**
 * Global object where all controllers are stored
 */
let controllers = {};

export const keyMapping = {
  salesForecastByProbability: "salesForecastByProbability",
  kpisValues: "kpisValues",
  kpiAggregates: "kpiAggregates",
  kpiValuesHistory: "kpiValuesHistory",
  kpiDimensionsForKpis: "kpiDimensionsForKpis",
  genericDeepdrill: "genericDeepdrill",
  recurringRevenue: "recurringRevenue",
  listview: "listview"
};

/**
 * Creates a controller and stores in the controllers object at specified key
 * @param {String} key which will be used to store the controller
 * @returns controller's signal
 */
export function createAbortController(key) {
  const controller = new AbortController();
  controllers[key] = controller;
  return controller.signal;
}

/**
 * Aborts the controller which is stored in the controllers object at the specified key
 * And deletes it
 * @param {String} key at which the controller which needs to be deleted is stored
 */
export function signalAbort(key) {
  if (!(key in controllers)) return;
  controllers[key].abort();
  delete controllers[key];
}

/**
 * Aborts the controllers which are stored in the controllers object at the specified keys
 * And deletes them, by calling `signalsAbort`
 * @param {String} key list of keys at which the controller which needs to be deleted is stored
 */
export function signalsAbort(keys) {
  for (let key of keys) {
    signalAbort(key);
  }
}

/**
 * Returns names of all created controllers
 * @returns names of controllers
 */
export function getAllExistingControllers() {
  return Object.keys(controllers);
}
