<template>
  <div id="app">
    <KeepAuth />
    <template v-for="notification in notifications">
      <b-alert
        :key="notification.id"
        style="white-space: pre"
        :variant="notification.variant"
        :show="true">
        {{ notification.message }}
      </b-alert>
    </template>

    <div
      v-if="userProfileLoadingState.loading"
      class="d-flex justify-content-center mt-4">
      <b-spinner
        small
        type="grow"
        variant="primary" />
    </div>
    <template v-if="isLoggedIn">
      <iframe
        v-for="instance in registeredInstances"
        :key="instance.id"
        v-show="selectedInstance && selectedInstance.id === instance.id"
        :src="`${instance.frontend_url}/iframelogin?${QS({ token: instance.token, theme })}`" />
    </template>
    <router-view v-show="!selectedInstance" />

    <!-- instance selector BEGIN -->
    <div
      v-if="
        isLoggedIn && company && company.isInvestorInstance && !$route.path.startsWith('/print/')
      ">
      <div class="instance-selector">
        <b-dropdown
          class="w-100"
          :disabled="instancesOptions.length <= 1">
          <template #button-content>
            <div class="d-flex justify-content-between w-100">
              <span>{{ getInstanceName(selectedInstance) }}</span>
              <DropdownCaret />
            </div>
          </template>
          <b-dropdown-item
            v-for="instance in instancesOptions"
            :key="instance ? instance.id : -1"
            @click="selectedInstance = instance">
            {{ getInstanceName(instance) }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- instance selector END -->
      <LogoutButton
        class="master-logout-button"
        v-if="selectedInstance !== null" />
    </div>
  </div>
</template>

<script>
////////////////////////
// Generic hotjar plugin - should be the same for all instances
if (process.env.VUE_APP_GENERIC_HOTJAR_SITE_ID) {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: process.env.VUE_APP_GENERIC_HOTJAR_SITE_ID, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}
////////////////////////

import DropdownCaret from "@/components/DropdownCaret.vue";
import LogoutButton from "@/components/LogoutButton.vue";
import KeepAuth from "@/components/utils/KeepAuth";
import { mapGetters } from "vuex";
import { registerLicense } from "@syncfusion/ej2-base";
import { syncfusionLicense } from "@/preferences";

registerLicense(syncfusionLicense);

import { QS } from "@/services/util";
export default {
  components: {
    KeepAuth,
    DropdownCaret,
    LogoutButton
  },
  data: function () {
    return {
      selectedInstance: null
    };
  },
  computed: {
    ...mapGetters([
      "notifications",
      "userProfileLoadingState",
      "company",
      "registeredInstances",
      "theme",
      "isLoggedIn"
    ]),

    instancesOptions() {
      return [null, ...this.registeredInstances];
    }
  },
  watch: {
    isLoggedIn(val) {
      if (!val) {
        this.selectedInstance = null;
      }
    }
  },
  created() {
    // Watch for global system changes.
    // Ex.: User has no preselected theme in our app, but his global theme is dynamic
    //      This listener ensures we capture that event live
    const systemThemeLight = window.matchMedia("(prefers-color-scheme: light)");
    systemThemeLight.addEventListener("change", this.initTheme);
    this.$once("hook:beforeDestroy", () => {
      systemThemeLight.removeEventListener("change", this.initTheme);
    });
  },
  mounted() {
    this.initTheme();
    this.$store.commit("autoLogoutTimerClear");
  },
  methods: {
    initTheme() {
      this.$store.dispatch("initThemeFromPreferences");
    },
    getInstanceName(instance) {
      return instance === null ? this.company.name : instance.instance_name;
    },
    QS(q) {
      return QS(q);
    }
  }
};
</script>

<style lang="scss">
@import "scss/main.scss";

.instance-selector {
  position: fixed;
  top: 16px;
  right: 150px;
  z-index: 1030;
  width: 180px;
}

.master-logout-button {
  position: fixed;
  top: 7px;
  right: 32px;
  z-index: 1030;
}

iframe {
  display: block;
  height: 100vh;
  width: 100vw;
  border: none;
}
</style>
