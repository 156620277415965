import {
  updateInvestorReportingSnapshot,
  updateInvestorReportingSnapshotMeta,
  deleteInvestorReportingSnapshot,
  createInvestorReportingSnapshot,
  getSectionVariants,
  getSchemaTemplates,
  getInvestorReportingSnapshots,
  getInvestorReportingSnapshot
} from "@/services/investorReportingService.js";
import { getKpiAggregates, getKpiHistory, getKpis } from "@/services/kpiService.js";
import {
  stateError,
  stateInitial,
  stateLoaded,
  stateLoading,
  executeTryCatchCallbacks
} from "./util";
import store from "@/store";
import dayjs from "dayjs";
import { deepCopy } from "vw/okr";

const initialState = {
  reports: null,
  reportsLoadingState: stateInitial,

  selectedReport: null,
  selectedReportDetails: null,

  reportToDelete: null,

  selectedReportTemplate: null,

  isEditReportMode: false,

  sectionVariants: [],

  schemaTemplates: [],

  kpiSectionSelectedView: {}
};

const mutations = {
  setReports(state, reports) {
    state.reports = reports;
  },
  setReportsLoadingState(state, loadingState) {
    state.reportsLoadingState = loadingState;
  },
  setSelectedReport(state, report) {
    state.selectedReport = report;
  },
  setSelectedReportData(state, data) {
    state.selectedReport.data = data;
  },
  setSelectedReportDetails(state, report) {
    state.selectedReportDetails = report;
  },
  setReportToDelete(state, report) {
    state.reportToDelete = report;
  },
  setIsReportEditMode(state, val) {
    state.isEditReportMode = val;
  },
  setSelectedReportTemplate(state, report) {
    state.selectedReportTemplate = report;
  },
  setSectionVariants(state, sectionVariants) {
    state.sectionVariants = sectionVariants;
  },
  setSchemaTemplates(state, schemaTemplates) {
    state.schemaTemplates = schemaTemplates;
  },
  setKpiSectionSelectedView(state, { sectionSlug, isTableView }) {
    state.kpiSectionSelectedView[sectionSlug] = isTableView;
  }
};

// Callback to set the error state. DRY
const setErrorState = error => {
  store.commit("setReportsLoadingState", {
    ...stateError,
    errorMessage: error
  });
};

// Syntax-sugar to include `setErrorState` just once
async function executeTryCatchCallbacksWithSetErrorState(tryCb) {
  await executeTryCatchCallbacks(tryCb, setErrorState);
}

const actions = {
  async loadReports(context, { fetchCallback = getInvestorReportingSnapshots } = {}) {
    context.commit("setReportsLoadingState", stateLoading);
    await executeTryCatchCallbacksWithSetErrorState(async () => {
      const response = await fetchCallback();
      context.commit("setReports", response.data);
    });

    context.commit("setReportsLoadingState", stateLoaded);
  },
  async loadReportDetails(
    context,
    { id, suppressLoadingAnimation, fetchCallback = getInvestorReportingSnapshot } = {}
  ) {
    if (!suppressLoadingAnimation) {
      context.commit("setReportsLoadingState", stateLoading);
    }

    const fiscalYearOffset = store.state.okrModule.company?.fiscal_year_offset || 0;
    const leftOffsetDate = d => dayjs(d).subtract((12 - fiscalYearOffset) % 12, "month");

    await executeTryCatchCallbacksWithSetErrorState(async () => {
      const response = await fetchCallback(id);
      const irData = response.data;
      // check if data needs to be injected from the go endpoint
      // Note: this won't work as expected on a holding instance, you'll have to change the endpoint
      if (irData.status === "draft") {
        // inject financial reporting data from the go api

        let financialEndDate = dayjs(irData.financial_end_date);
        const lastFiscalYear =
          financialEndDate.month() >= fiscalYearOffset && fiscalYearOffset
            ? financialEndDate.year() + 1
            : financialEndDate.year();
        const timeRange = ((financialEndDate.month() + 12 - fiscalYearOffset) % 12) + 1;

        financialEndDate = financialEndDate.format("YYYY-MM-DD");

        // select the section slugs
        const financialSections =
          irData.schema.sections?.filter(s => s.variant === "financial_reporting") || [];

        for (let financialSection of financialSections) {
          let financialData = irData.data[financialSection.slug].data;
          Object.keys(financialData).forEach(async year => {
            irData.data[financialSection.slug].data[year] = {
              aggregates: (
                await getKpiAggregates({
                  endDate:
                    year == lastFiscalYear
                      ? financialEndDate
                      : leftOffsetDate(`${year}-12-01`).format("YYYY-MM-DD"),
                  timeRange: year == lastFiscalYear ? timeRange : 12,
                  type: "financial"
                })
              ).data.results,
              history: (
                await getKpiHistory({
                  endDate:
                    year == lastFiscalYear
                      ? financialEndDate
                      : leftOffsetDate(`${year}-12-01`).format("YYYY-MM-DD"),
                  timeRange: year == lastFiscalYear ? timeRange - 1 : 11,
                  bucketSize: 1,
                  type: "financial"
                })
              ).data.results,
              values: (
                await getKpis({
                  date:
                    year == lastFiscalYear
                      ? financialEndDate
                      : leftOffsetDate(`${year}-12-01`).format("YYYY-MM-DD"),
                  includePlanning: financialSection.include_planning,
                  type: "financial",
                  idList: irData.data[financialSection.slug].kpis.map(kpi => kpi.id)
                })
              ).data.kpis,
              allValues: (
                await getKpis({
                  date:
                    year == lastFiscalYear
                      ? financialEndDate
                      : leftOffsetDate(`${year}-12-01`).format("YYYY-MM-DD"),
                  includePlanning: financialSection.include_planning,
                  type: "financial"
                })
              ).data.kpis
            };
          });
        }
      }
      context.commit("setSelectedReportDetails", irData);
    });

    context.commit("setReportsLoadingState", stateLoaded);
  },
  setSelectedReportByIndex(context, { index }) {
    if (context.state.reports == null || context.state.reports.length == 0) {
      context.commit("setSelectedReport", null);
      context.commit("setSelectedReportDetails", null);
      return;
    }

    const reportByIndex = context.state.reports[index];
    if (reportByIndex) {
      context.commit("setSelectedReport", reportByIndex);
    }
  },
  setSelectedReportById(context, { id }) {
    const report = context.state.reports.find(r => r.id === id);
    if (report) {
      context.commit("setSelectedReport", report);
    } else {
      context.dispatch("setSelectedReportByIndex", { index: 0 });
    }
  },
  async approveReport(context) {
    await executeTryCatchCallbacksWithSetErrorState(async () => {
      await updateInvestorReportingSnapshotMeta(context.state.selectedReport.id, {
        id: context.state.selectedReport.id,
        status: "approved",
        data: context.state.selectedReportDetails.data
      });

      const loadDetailsForId = context.state.selectedReport?.id;
      await context.dispatch("loadReports", { loadDetailsForId });
      await context.dispatch("loadReportDetails", { id: context.state.selectedReport.id });
    });
  },
  async sendReport(context, payload) {
    const message = payload?.message;
    const attachPdf = payload?.attachPdf;
    const theme = payload?.theme;
    const subject = payload?.subject;

    await executeTryCatchCallbacksWithSetErrorState(async () => {
      await updateInvestorReportingSnapshotMeta(context.state.selectedReport.id, {
        id: context.state.selectedReport.id,
        status: "sent",
        data: context.state.selectedReportDetails.data,
        message,
        attachPdf,
        theme,
        subject
      });

      const loadDetailsForId = context.state.selectedReport?.id;
      await context.dispatch("loadReports", { loadDetailsForId });
      await context.dispatch("loadReportDetails", { id: context.state.selectedReport.id });
    });
  },
  async saveVersion(context, { payload }) {
    await executeTryCatchCallbacksWithSetErrorState(async () => {
      if (context.state.isEditReportMode) {
        await updateInvestorReportingSnapshot(context.state.selectedReport.id, {
          ...payload,
          id: context.state.selectedReport.id
        });
      } else {
        await createInvestorReportingSnapshot(payload);
      }

      await context.dispatch("loadReports");
    });
  },
  async deleteReport(context, { report }) {
    await executeTryCatchCallbacksWithSetErrorState(async () => {
      await deleteInvestorReportingSnapshot(report.id);
      await context.dispatch("loadReports");
    });
  },
  async toggleReportVisibility(context, { report }) {
    await executeTryCatchCallbacksWithSetErrorState(async () => {
      await updateInvestorReportingSnapshotMeta(report.id, {
        id: report.id,
        is_visible: !report.is_visible
      });

      const loadDetailsForId = context.state.selectedReport?.id;
      await context.dispatch("loadReports", { loadDetailsForId });
    });
  },

  setSectionVariants(context) {
    executeTryCatchCallbacksWithSetErrorState(async () => {
      const response = await getSectionVariants();
      context.commit("setSectionVariants", response.data);
    });
  },
  setSchemaTemplates(context) {
    executeTryCatchCallbacksWithSetErrorState(async () => {
      const response = await getSchemaTemplates();
      context.commit("setSchemaTemplates", response.data);
    });
  },
  updateSnapshotMetaSectionFields(context, { sectionSlug, payload }) {
    const sections = deepCopy(context.state.selectedReportDetails.schema.sections);
    const newSections = sections.map(section =>
      section.slug == sectionSlug ? { ...section, ...payload } : section
    );
    context.commit("setSelectedReportDetails", {
      ...context.state.selectedReportDetails,
      schema: { sections: newSections }
    });
  }
};

const getters = {
  reports: state => state.reports,
  reportsLoadingState: state => state.reportsLoadingState,
  reportIdToIndexMap: (_, getters) =>
    getters.reports?.reduce((map, el, index) => {
      map[el.id] = index;
      return map;
    }, {}),

  selectedReport: state => state.selectedReport,
  selectedReportDetails: state => state.selectedReportDetails,

  reportToDelete: state => state.reportToDelete,

  selectedReportTemplate: state => state.selectedReportTemplate,

  isEditReportMode: state => state.isEditReportMode,

  sectionVariants: state => state.sectionVariants,
  schemaTemplates: state => state.schemaTemplates,

  kpiSectionSelectedView: state => state.kpiSectionSelectedView
};

export const investorReportingModule = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations
};
