import Vue from "vue";
import Vuex from "vuex";
import { authModule } from "./authModule";
import { twoFactorAuthModule } from "./twoFactorAuthModule";
import { kpisModule } from "./kpiModule";
import { kpiActionsModule } from "./kpiActionModule";
import { notificationsModule } from "./notificationModule";
import { okrModule } from "./okrModule";
import { deepDrillsModule } from "./deepDrillsModule";
import { uploadModule } from "./uploadModule";
import { dateModule } from "./dateModule";
import { investorReportingModule } from "./investorReportingModule";
import { holdingModule } from "./holdingModule";
import { chatgptSettingsModule } from "./chatgptSettingsModule";
import { settingsModule } from "./settingsModule";
import { themeModule } from "./themeModule";
import { oauthModule } from "./oauthModule";
import { integrationSummariesModule } from "./integrationSummariesModule";
import { integrationModule } from "./integrationModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authModule,
    twoFactorAuthModule,
    kpisModule,
    kpiActionsModule,
    notificationsModule,
    uploadModule,
    okrModule,
    deepDrillsModule,
    dateModule,
    investorReportingModule,
    holdingModule,
    chatgptSettingsModule,
    settingsModule,
    themeModule,
    oauthModule,
    integrationSummariesModule,
    integrationModule
  }
});
